import {origin} from 'mainConfig';

const TT_ENDPOINT_REGEX = new RegExp(`^https?://\\w?.?${origin}`);

class TomtomSourcesValidator {
    _isTomtomEndpoint = (endpoint) => {
        return TT_ENDPOINT_REGEX.test(endpoint);
    }

    _isSourceUsingTomtomEndpoint = (source) => {
        const tiles = source.tiles;
        const url = source.url;

        if (tiles) {
            for (const endpoint of Object.keys(tiles)) {
                if (this._isTomtomEndpoint(tiles[endpoint])) {
                    return true;
                }
            }
        } else if (url) {
            return this._isTomtomEndpoint(url);
        }

        return false;
    }

    _getLayerIdFromSourceName = (name, visisbleLayers) => {
        for (const layer of Object.keys(visisbleLayers)) {
            if (visisbleLayers[layer].source === name) {
                return visisbleLayers[layer].id;
            }
        }

        return null;
    }

    _getVisibleLayersAndSources = (map, style) => {
        const visisbleLayers = {};
        const visibleSources = {};

        style.layers.forEach(layer => {
            const visibilityLayoutProperty = map.getLayoutProperty(layer.id, 'visibility');
            if (layer.source && (visibilityLayoutProperty === 'visible' || visibilityLayoutProperty === undefined)) {
                visibleSources[layer.source] = style.sources[layer.source];
                visisbleLayers[layer.id] = layer;
            }
        });

        return [visisbleLayers, visibleSources];
    }

    _isProperSourceType = (source) => {
        return ['raster', 'vector'].some(type => type === source.type);
    }

    _validateCachedLayer = (map, style, cachedLayer) => {
        const layer = map.getLayer(cachedLayer);

        if (layer && map.getLayoutProperty(layer.id, 'visibility') === 'visible') {
            const source = style.sources[layer.source];

            if (source && this._isSourceUsingTomtomEndpoint(source)) {
                return true;
            }
        }

        return false;
    }

    _getValidLayer = (style, [visibleLayers, visibleSources]) => {
        for (const source of Object.keys(visibleSources)) {
            if (this._isProperSourceType(visibleSources[source])) {
                if (this._isSourceUsingTomtomEndpoint(style.sources[source])) {
                    return this._getLayerIdFromSourceName(source, visibleLayers);
                }
            }
        }

        return undefined;
    };
    validate = (map) => {
        const style = map.getStyle();

        if (map._svCachedLayerId) {
            if (this._validateCachedLayer(map, style, map._svCachedLayerId)) {
                return;
            }
        }

        map._svCachedLayerId = this._getValidLayer(style, this._getVisibleLayersAndSources(map, style));

        if (!map._svCachedLayerId) {
            throw new Error(
                `At least one ${origin} endpoint must be present in the source of any visible layer.`
            );
        }
    }
}

export default {
    tomtomSourcesValidator: () => new TomtomSourcesValidator(),
    tomtomSourcesValidatorHandler: function(map) {
        window.setTimeout(() => {
            try {
                this.tomtomSourcesValidator().validate(map);
            } catch (error) {
                console.error(error.message);
                if (map.getStyle().layers.length === 0) {
                    return;
                }

                map.setStyle({
                    version: 8,
                    sources: {},
                    layers: []
                });
            }
        }, 0);
    },
    tearDown: (map) => {
        delete map._svCachedLayerId;
    }
};

