import uuid from 'uuid/v4';

const TWELVE_HOURS_TIMEOUT = 43200000;

class SessionId {
    constructor() {
        this._timeout = TWELVE_HOURS_TIMEOUT;
        this._sessionId = null;
        this._intervalId = null;
    }

    initialize = (map) => {
        map.once('remove', this._onRemove);
    }

    create = () => {
        this._sessionId = uuid();

        if (!this._intervalId) {
            this._initializeTimer();
        }
    }

    get = () => {
        return this._sessionId;
    }

    _initializeTimer = () => {
        this._intervalId = window.setInterval(this.create, this._timeout);
    }

    _onRemove = () => {
        if (this._intervalId) {
            clearInterval(this._intervalId);
        }
    }
}

let sessionIdInstance;

export default {
    factory: () => new SessionId(),
    getInstance: function() {
        if (!sessionIdInstance) {
            sessionIdInstance = this.factory();
        }
        return sessionIdInstance;
    }
};
