import {debounce} from 'lodash';

import validator from './validateUsageOfTomtomSources';

function setUp(map) {
    const validateSources = debounce(
        () => {
            validator.tomtomSourcesValidatorHandler(map);
        },
        100,
        {
            leading: true,
            trailing: false
        }
    );
    // eslint-disable-next-line prefer-const
    let timeoutHandler;
    const onRemove = () => {
        clearTimeout(timeoutHandler);
        map.off('styledata', validateSources);
        validator.tearDown(map);
        map.off('remove', onRemove);
    };

    timeoutHandler = window.setTimeout(() => {
        validator.tomtomSourcesValidatorHandler(map);
        map.on('styledata', validateSources);
    }, 2000);
    map.once('remove', onRemove);

    //source validator destructor
    map._svd = onRemove;
}

function tearDown(map) {
    if (map._svd) {
        map._svd();
        delete map._svd;
    }
}

export default {
    setUp,
    tearDown
};
